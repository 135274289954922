/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import initToggle from './modules/toggle-container'
import mobileNavigation from './modules/mobile-navigation'
import initItem from './modules/item'
import Swiper from 'swiper/dist/js/swiper'
import notifier from './vendor/notifier'
import debug from './modules/debug'
import initAffix from './modules/affix.js'

/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    console.log(document.getElementById('message-notifer'))
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  initItem()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  initToggle()
  initAffix()
  debug()
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    pagination: '.meinunge-page'
  })

  // eslint-disable-next-line
  new Swiper('#vn-bilder', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    pagination: '.vn-bilder-page'
  })

  // eslint-disable-next-line
  new Swiper('.slider-js', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: {
      delay: 6000
    },
    speed: 2000,
    paginationClickable: true,
    loop: true
  })

// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 6000,
    nextButton: '.leistung-button-next',
    prevButton: '.leistung-button-prev',
    loop: true,
    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  })

// eslint-disable-next-line
  new Swiper('.image-slider-container', {
    pagination: {
      el: '.image-slider-page'
    },
    autoplay: {
      delay: 3000
    },
    speed: 2000,
    loop: true,
    centeredSlides: true
  })

  // eslint-disable-next-line
  new Swiper('.leistungs-swiper', {
    navigation: {
      nextEl: '.swiper-button-prev',
      prevEl: '.swiper-button-next'
    },
    speed: 1000,
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    }
  })

  // var swiper = new Swiper('.image-slider-container', {
  //   pagination: {
  //     el: '.image-slider-page',
  //   },
  //   autoplay: {
  //     delay: 3000,
  //   },
  //   speed: 2000,
  //   loop:true
  // });

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
})
