import $ from 'jquery'

export default function init () {
  var target = $('.main-nav__container')
  var offset = 102 // px

  target.after('<div class="affix" id="affix"></div>')
  var affix = $('.affix')
  affix.append(target.clone(true))

// Show affix on scroll.
  var element = document.getElementById('affix')

  if (element !== null) {
    var position = target.position()
    window.addEventListener('scroll', function () {
      var height = $(window).scrollTop()
      if (height > position.top + offset) {
        target.css('visibility', 'hidden')
        affix.css('display', 'block')
      } else {
        affix.css('display', 'none')
        target.css('visibility', 'visible')
      }
    })
  }
}
